import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {GatsbyImage} from "gatsby-plugin-image"
import Seo from "../components/Seo";

const OptInPage = ({data}) => {
    const MySwal = withReactContent(Swal)
    const {wpPage: page} = data;
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    function renderBg() {
        return (
            <h1 className="page-heading">{page.acf_contact.title}</h1>
        )
    }

    function renderImage(image, alt) {
        if (!image) {
            return null;
        }

        return (
            <GatsbyImage alt="" image={image.localFile.childImageSharp.gatsbyImageData}/>
        )
    }


    return (
        <Layout>
            <Seo seo={page.seo}/>
            {renderBg()}
            <div className="bg-white">
                <div className="container w-5/6 lg:w-full">
                    <div className="pt-20 text-lg">
                        <div className="grid grid-cols-1 text-center">
                            <div>
                                <h2 className="text-2xl font-medium mb-3" id="contactForm">
                                    {page.acf_contact.titleBlock1}
                                </h2>
                                <div dangerouslySetInnerHTML={{__html: page.acf_contact.textBlock1}}/>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 mx-auto my-10">
                        <div className="form w-full text-center">
                            <Formik
                                initialValues={{
                                    email: '',
                                    botfield: '',
                                }}
                                onSubmit={
                                    (values, actions) => {
                                        fetch("/", {
                                            method: "POST",
                                            headers: {"Content-Type": "application/x-www-form-urlencoded"},
                                            body: encode({"form-name": "opt-in", ...values})
                                        })
                                            .then(() => {
                                                actions.resetForm()
                                                MySwal.fire({
                                                    title: <strong>Bedankt voor je aanvraag!</strong>,
                                                    footer: 'Vita Belle Coaching',
                                                    html: 'Je ontvangt ...',
                                                    confirmButtonText: 'Sluiten'
                                                });
                                            })
                                            .catch(() => {
                                            })
                                            .finally(() => actions.setSubmitting(false))
                                    }
                                }
                                validate={values => {
                                    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                                    const errors = {};

                                    if (!values.email || !emailRegex.test(values.email)) {
                                        errors.email = 'Een valide e-mailadres is verplicht'
                                    }

                                    if (values.botfield) {
                                        errors.botfield = 'you are a bot';
                                    }

                                    return errors;
                                }}
                            >
                                {() => (
                                    <Form method="post" name="opt-in" data-netlify={true} netlify-honeypot="botfield">
                                        <div className="text-left">
                                            <label htmlFor="email"
                                                   className="block uppercase text-sm text-gray-600 font-bold text-center">E-mailadres </label>
                                            <Field name="email" type="email" className="mx-auto block"/>
                                            <div className="text-red-500 italic">
                                                <ErrorMessage name="email"/>
                                            </div>
                                        </div>
                                        <p className="hidden">
                                            <label>
                                                Don’t fill this out if you’re human: <Field name="botfield" as="input" type="text"/>
                                            </label>
                                            <ErrorMessage name="botfield" />
                                        </p>
                                        <button type="submit">Aanvragen</button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default OptInPage;

export const OptInPageQuery = graphql`
    query OptIn($id: String!) {
        wpPage(id: { eq: $id }) {
            seo {
                canonical
                title
                metaDesc
                focuskw

            }
            uri
            title
            content
            acf_contact {
                textBlock1
                title
                titleBlock1
                imageBlock1 {
                    id
                    mediaItemUrl
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                    }
                }


            }
        }
    }`;
